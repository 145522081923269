module config {
    export function momentDateFilter() {
        return function (date: moment.Moment, format) {
            var formatedDate = "";
            if (date && format) {
                formatedDate = date.format(format);
            }

            return formatedDate;
        }
    }

    export function startsWithFilter() {
        return function (items, letter) {

            if (!items)
                return;

            var filtered = [];
            var letterMatch = new RegExp(letter, 'i');
            for (var i = 0; i < items.length; i++) {
                var item = items[i];

                if (item.name) {
                    if (letterMatch.test(item.name.substring(0, 1))) {
                        filtered.push(item);
                    }
                } else {
                    if (letterMatch.test(item.substring(0, 1))) {
                        filtered.push(item);
                    }
                }
            }
            return filtered;
        }
    }
}

angular.module("app", ["ngRoute", "ngResource", "ngAria", "ui.grid", "ui.grid.treeView",'ui.grid.autoResize',
    'ui.grid.cellNav', 'ui.grid.edit', 'ui.grid.resizeColumns',
    'ui.grid.pinning', 'ui.grid.selection', 'ui.grid.moveColumns',
    'ui.grid.exporter', 'ui.grid.importer', 'ui.grid.grouping',
    'ui.grid.rowEdit', 'ngCookies', 'ui.router', 'ngLocale',
    'ui.grid.pagination', 'config_env', 'ngStorage', 'ngMessages', 'ui.bootstrap', 'ngAnimate',
    'ncy-angular-breadcrumb', 'bsLoadingOverlay', 'naif.base64', 'ngSanitize', 'nvd3', 'ui.select', 'angularTrix'
        , 'MsalAngular'
    ])
    .filter('momentDateFilter', config.momentDateFilter)
    .filter('startsWith', config.startsWithFilter)
    .run(function (bsLoadingOverlayService) {
        bsLoadingOverlayService.setGlobalConfig({
            templateUrl: 'templates/modules/applicationcore/loadingOverviewView.html'
        });
    }).directive("customFormat", ($filter) => {
        return {
            require: 'ngModel',
            link: (scope, element, attrs, ngModelController: ng.IController) => {
                if (!ngModelController) return;

                ngModelController.$formatters.unshift((a) => {
                    return $filter(attrs.customFormat)(ngModelController.$modelValue,2)
                });

                element.bind('blur', (event) => {
                    var plainNumber = (<string>element.val()).replace(/^[\u]*$/, '');
                    element.val($filter(attrs.customFormat)(plainNumber));
                });
            }
        };
    }).directive("ignoreWheel", ($filter) => {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: (scope, element, attrs, ngModelController: ng.IController) => {
                if (!ngModelController) return;

                element.on('focus', function () {
                    angular.element(this).on('DOMMouseScroll mousewheel onmousewheel keydown', function (e) {
                        if (!e.keyCode || e.keyCode === 38 || e.keyCode === 40) {
                           e.preventDefault();
                        }
                    });
                });

                element.on('blur', function () {

                    angular.element(this).off('DOMMouseScroll mousewheel onmousewheel keydown');
                });           
            }
        };
    }).filter('trusted', ['$sce', ($sce: ng.ISCEService) => {
        return (url) => {
            return $sce.trustAsResourceUrl(url);
        };
    }]);